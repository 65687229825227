@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Page Top
// ==================================================
.page-top {
  padding-top: 0;

  .pagePost {
    margin-top: 0;
  }
}

// ==================================================
//  Mainvisual
// ==================================================
.mainvisual {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;

  @include mixin.pc {
    min-height: 620px;
  }

  @include mixin.sp {
    height: 100svh;
  }

  // Splide Slider
  .splide {
    width: 100%;
    overflow: hidden;

    .splide-outer {
      width: 100%;
      max-width: none;
    }
    .splide__slide {
      a {
        display: block;
        width: 100%;
        @include mixin.hoverOpacity;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
}
